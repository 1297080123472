import React, { Component } from 'react'
import './dropzone.css'

class Dropzone extends Component {
  constructor(props) {
    super(props)
    this.state = { hightlight: false }
    this.fileInputRef = React.createRef()

    this.openFileDialog = this.openFileDialog.bind(this)
    this.onFilesAdded = this.onFilesAdded.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }
  fileListToArray(list) {
    console.log(list)
    const array = [list.item(0)];
    console.log(array)
    // for (var i = 0; i < list.length; i++) {
    //   array.push(list.item(0));
    // }
    return array;
  }

  onDragOver(evt) {
    evt.preventDefault();
  
    if (this.props.disabled) return;
  
    this.setState({ highlight: true });
  }

  onDragLeave() {
    this.setState({ highlight: false });
  }

  onDrop(event) {
    event.preventDefault();
  
    if (this.props.disabled) return;
  
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ highlight: false });
  }

  render() {

    return (
      <div className={`Dropzone ${this.state.highlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}>
        <img
          alt="upload"
          className="Icon"
          src="/assets/images/cloud-upload.svg"
        />
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          accept="image/*"
          onChange={this.onFilesAdded}
        />
        <span>Add a Photo</span>
      </div>
    );
  }
}

export default Dropzone;