import React, {Component} from 'react';
import { Link, NavLink} from 'react-router-dom';
import $ from 'jquery';
import 'smartmenus';

class SideBar extends Component {

    componentWillMount (){
        $(function() {
            $('#sub-menu').smartmenus({
                subMenusSubOffsetX: 1,
                subMenusSubOffsetY: -8
            });
        });
    }

    closeNav() {
        var closemyslide = document.getElementById("mySidenav");
        if(closemyslide)
            closemyslide.classList.remove('open-side');
    }

    render() {
        return (
            <div id="mySidenav" className="sidenav">
                <a href="javascript:void(0)" className="sidebar-overlay" onClick={this.closeNav}></a>
                <nav>
                    <div onClick={this.closeNav}>
                        <div className="sidebar-back text-left">
                            <i className="fa fa-angle-left pr-2" aria-hidden="true"></i> Back
                        </div>
                    </div>
                    {/*Vertical Menu*/}
                    <ul id="sub-menu" className="sm pixelstrap sm-vertical ">
                        <li><Link to={`${process.env.PUBLIC_URL}/`} >home</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/custom`} >design tool</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/products`} >product range</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/questions`} >FAQ</Link></li>
                        <li><Link to={`${process.env.PUBLIC_URL}/links`} >useful links</Link></li>
                    </ul>
                </nav>
            </div>
        )
    }
}


export default SideBar;