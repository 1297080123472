import React, { Component, Fragment } from 'react';
import Post from '../../post/post';
import { Link } from 'react-router-dom';
import AuthService from '../../../services/auth';
import axios from 'axios';

class TalesGallery extends Component {

  constructor(props) {
    super(props)
    this.state = {
      posts: [],
      user: null
    }
    this.checkUser()
  }

  componentWillMount() {
    this.getPosts()
  }

  getPosts = async () => {
    const url = 'https://mittys-api.bento.solutions/api/'
    // const url = 'http://localhost:8080/api/'
    const posts = await axios.get(url + 'posts/get')
    this.setState({ posts: posts.data }, () => {
    })
  }


  checkUser = async () => {
    const user = await AuthService.getUser();
    if (user !== this.state.user) {
      this.setState({ user: user.user }, () => { });
    }
  }

  removePost = (index) => {
    return this.setState({ posts: this.state.posts.slice(index, 1) })
  }

  render() {

    const { posts, user } = this.state
    return (
      <Fragment>

        <div className="TalesApp">
          <div className="TalesHeader">
            <h1>HORSE TALES</h1>
            <hr id="home-hr" className="page-hr" />
            <p><strong>Horse Racing has stood the test of time and so have their stories.</strong></p>
            <p>In these extremely hard times, we thought you might be fun and bring smiles to all our faces by sharing your racing stories.</p>
            {
              this.state.user ?
                <Link to={`${process.env.PUBLIC_URL}/post/upload`} className="btn btn-solid">Add Post</Link>
                :
                <Link to={`${process.env.PUBLIC_URL}/login`} className="btn btn-solid">Sign in to post</Link>
            }

          </div>
          {
            posts.map((post, index) => {
              return (
                <div className="TalesCard" key={post._id}>
                  <Post post={post} user={user} getPosts={this.getPosts} />
                </div>
              )
            })
          }
        </div>

      </Fragment>


    )
  }
}

export default TalesGallery;