import React from 'react'
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import axios from 'axios'
import './checkout.css';
const url = 'https://mittys-api.bento.solutions/api/'
// const url = 'http://localhost:8080/api/'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#df1234',
      color: '#23272A',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': { color: '#fce883' },
      '::placeholder': { color: '#87bbfd' },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

const getPaymentIntent = (state) => {
  return axios.post(url + 'secret', { price: state.amount })
}

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      postal: '',
      errorMessage: null,
      paymentMethod: null,
      paymentIntent: null,
      total: this.props.details.total,
      disableButton: false
    };
  }

  componentDidMount() {
    getPaymentIntent(this.props.details)
      .then(({ data }) => {
        this.setState({ paymentIntent: data.client_secret })
      })
  }
  componentDidUpdate() {
    if (this.props.details.total != this.state.total) {
      getPaymentIntent(this.props.details)
        .then(({ data }) => {
          this.setState({ paymentIntent: data.client_secret, total: this.props.details.total })
        })
    }

  }
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ disableButton: true })
    const { stripe, elements } = this.props;
    if (this.props.validator.allValid()) {
      console.log(stripe)
      console.log(elements)
      console.log(this.state.paymentIntent)
      if (!stripe || !elements || !this.state.paymentIntent) {
        console.log('Stripe not loaded?')
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const payload = await stripe.confirmCardPayment(this.state.paymentIntent, {
        payment_method: {
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            name: this.props.details.firstName + ' ' + this.props.details.lastName,
            email: this.props.details.email,
            phone: this.props.details.phone,
            address: {
              city: this.props.details.city,
              line1: this.props.details.address,
              postal_code: this.props.details.postcode,
              state: this.props.details.state
            }
          }
        }
      });
      console.log(payload)
      if (payload.error) {
        this.props.payError(payload.error)
        this.props.prePay()
        console.log('[error]', payload.error);
        this.setState({ disableButton: false })
        this.setState({ errorMessage: payload.error.message })
      } else {
        // console.log('[PaymentMethod]', payload);
        this.props.pay()

      }
    }
    else {
      this.props.validator.showMessages()
      this.props.update();
      this.setState({ disableButton: false })
    }

  };

  render() {
    const { errorMessage, disableButton } = this.state;
    const { stripe } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement options={CARD_OPTIONS} />
        {errorMessage ?
          <div style={{ width: "100%", color: "red", textAlign: "center" }}>
            <p style={{ width: "100%", color: "red", textAlign: "center" }}>{errorMessage}</p>
          </div>
          :
          null
        }
        <button className="btn btn-solid payment-btn" type="submit" disabled={!stripe || disableButton}>
          Pay ${this.props.details.total.toFixed(2)}
        </button>
      </form>
    );
  }
}

const InjectedCheckoutForm = (props) => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm elements={elements} stripe={stripe} details={props.details} validator={props.validator} update={props.update} pay={props.pay} payError={props.payError} prePay={props.prePay} />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm;

