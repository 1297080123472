import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import HeaderOne from "../../common/headers/header-one";
import FooterOne from "../../common/footers/footer-one";
import Breadcrumb from '../../common/breadcrumb';

class UsefulLinks extends Component {

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Mittys | Useful Links</title>
          <meta name="description" content="Mittys – Racing and Jockey Colours" />
        </Helmet>
        <HeaderOne history={this.props.history} />
        <Breadcrumb title={'Useful Links'} />
        <br />
        <section className="links-section">
          <div className="container innerpagebg">
            <div className="row links-row">
              <div className="col-md-6 col-sm-6 content-txt">
                <h3 style={{ color: "#da1f3e" }} className="clr-red heading2">USEFUL LINKS</h3>
                <hr className="links-hr" />
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <p className="font-18 heading">Jockeys</p>
                    <div className="font-mypredo font-14 link">
                      <ul>
                        <li><a href="http://www.njt.org.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>National Jockeys Trust</a></li>
                        <li><a href="http://www.australianjockeys.org/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Australian Jockeys Association</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <p className="font-18 heading">Racing Associations</p>
                    <div className="font-mypredo font-14 link">
                      <ul>
                        <li><a href="http://www.waroa.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>WA Racehorse Owners Association</a>
                        </li>
                        <li><a href="http://www.vicbookmakers.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Victorian Bookmakers Association</a>
                        </li>
                        <li><a href="http://www.troa.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Thoroughbred Racehorse Owners
                        Association</a></li>
                        <li><a href="http://www.nswroa.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>NSW Racehorse Owners Asscoiation</a>
                        </li>
                        <li><a href="http://homeofracing.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>NSW Trainers Association</a></li>
                        <li><a href="http://www.countryracing.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Country Racing Victoria</a></li>
                        <li><a href="http://www.austrainers.com.au/page/1/home.html" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Australian Trainers Association</a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="row mgt-40">
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <p className="font-18 heading">Race Clubs (Metro)</p>
                    <div className="font-mypredo font-14 link">
                      <ul>
                        <li><a href="http://www.flemington.com.au/club-membership" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Victoria Racing Club</a></li>
                        <li><a href="http://www.perthracing.org.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>The Western Australian Turf Club</a>
                        </li>
                        <li><a href="http://www.australianturfclub.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Sydney Turf Club</a></li>
                        <li><a href="http://www.sajc.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>South Australian Jockey Club</a>
                        </li>
                        <li><a href="https://thevalley.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Moonee Valley Racing Club</a></li>
                        <li><a href="http://mrc.racing.com/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Melbourne Racing Club</a></li>
                        <li><a href="http://www.darwinturfclub.org.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Darwin Turf Club</a></li>
                        <li><a href="http://www.thoroughbredpark.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Canberra Racing Club</a></li>
                        <li><a href="http://brc.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Brisbane Race Club</a></li>
                        <li><a href="http://www.australianturfclub.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Australian Turf Club</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <p className="font-18 heading">Principal Racing Authorities</p>
                    <div className="font-mypredo font-14 link">
                      <ul>
                        <li><a href="http://www.theracessa.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Thoroughbred Racing SA</a></li>
                        <li><a href="http://www.darwinturfclub.org.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Thoroughbred Racing NT</a></li>
                        <li><a href="http://tasracing.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Tas Racing</a></li>
                        <li><a href="http://www.racing.com/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Racing Victoria</a></li>
                        <li><a href="http://www.racingnsw.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>RacingNSW</a></li>
                        <li><a href="http://www.rwwa.com.au/home/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Racing & Wagering Western
                        Australia</a></li>
                        <li><a href="http://www.racingqueensland.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Racing Queensland</a></li>
                        <li><a href="http://www.thoroughbredpark.com.au/" target="_blank"><span
                          className="glyphicon glyphicon-chevron-right clr-red"> </span>Canberra Racing Club</a></li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="evans-ad">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-4 evans-link">
                <a href="https://evanevans.com.au/" target="_blank">
                  <img src="https://cdn.yworld.com.au/photo/logoblue@EvansEvans-image-300x0-1-f2b.png" />
                  <br />
                  <strong>CONTACT US NOW</strong>
                  <br />
                  <strong>1800 990 989</strong>
                </a>
              </div>
              <div className="col-md-8 col-sm-8">
                <h2 className="blue-evans">MAKING YOUR JOB EASY - EVERY TIME!</h2>
                <p>Evan Evans is at the forefront of the flag, banner and signage industry. We are one of Australia's leading largedigital printers and pride ourselves on producing the highest quality flag, banner, signage and event</p>
              </div>
            </div>
          </div>
        </section>
        <FooterOne />
      </Fragment>
    )
  }
}

export default UsefulLinks