import React, { Component } from 'react';
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import { GithubPicker } from 'react-color';
import { addToCart, addToWishlist, addToCompare, getAllProducts } from '../../../actions'
import { fabric } from 'fabric';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import * as _ from 'underscore';
import * as $ from 'jquery';
import Switch from "react-switch";
import jsPDF from 'jspdf';
import moment from 'moment';

class CustomDesign extends Component {

  constructor(props) {
    super(props)
    this.state = {
      pickerColors: ['#f5c6b8', '#009ee3', '#1c1c1b', '#006551',
        '#471f02', '#e72a77', '#5e5e5d', '#fff2cd', '#00976e', '#3aa935',
        '#ec6607', '#e14190', '#ffe800', '#fecb26', '#ef84ad', '#6bb534',
        '#842d47', '#8b7ab7', '#0f1b44', '#fbbf58', '#ec6b05', '#6bb9e8', '#f099c1',
        '#302783', '#de0332', "#26358b", '#f6b0a0', '#b0b2b4', '#ffffff', '#ffed00'],
      limit: 30,
      hasMoreItems: true,
      capCheck: false,
      jacketCheck: false,
      blinkersCheck: false,
      capColour: null,
      jacketColour: null,
      blinkersColour: null,
      capPattern: null,
      capPatternColour: null,
      JacketPattern: null,
      jacketPatternColour: null,
      sleevesPattern: null,
      sleevesPatternColour: null,
      blinkersPattern: null,
      blinkersPatternColour: null,
      selectedTab: 0
    }
  }


  componentDidMount() {
    this.canvas = new fabric.StaticCanvas('main-canvas', {
      preserveObjectStacking: true,
      height: 800,
      width: 330,
    })

    this.mobileCanvas = new fabric.StaticCanvas('mobile-canvas', {
      preserveObjectStacking: true,
      height: 170,
      width: 300,
    })
    const multiline = 'Please select options\n to customise'

    const text = new fabric.Text(multiline,
      {
        left: 25,
        top: 250,
        fontSize: 30,
        textAlign: 'center',
        fontFamily: 'Roboto',
        name: 'default1'
      });
    this.canvas.add(text)
  }


  addToCanvas = (checked, event, id) => {
    const e = {
      target: {
        name: id,
        checked: checked
      }
    }
    const text = new fabric.Text('Please select options \n to customise',
      {
        left: 25,
        top: 250,
        fontSize: 30,
        textAlign: 'center',
        fontFamily: 'Roboto',
        name: 'default1'
      });
    const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'default1' })
    this.canvas.remove(text1)
    if (e.target.name === 'cap-check') {
      this.setState({ capCheck: e.target.checked }, () => {
        if (!this.state.capCheck && !this.state.jacketCheck && !this.state.blinkersCheck) this.canvas.add(text)
      })
      if (e.target.checked) {
        fabric.Image.fromURL('/assets/images/customdesign/cap/cap1/white_cap.png', (img) => {
          img.set({
            top: 100,
            left: 100,
            name: 'cap'
          })
          img.scaleToHeight(100)
          img.scaleToWidth(100)
          this.canvas.add(img)
          this.mobilePreview()
        });

        fabric.Image.fromURL('/assets/images/customdesign/cap/Plain Cap.png', (img) => {
          img.set({
            top: 100,
            left: 100,
            name: 'cap-pattern'
          })
          img.scaleToHeight(100)
          img.scaleToWidth(100)
          this.canvas.add(img)
        })
      }
      else if (!e.target.checked) {
        const capPattern = _.find(this.canvas._objects, function (o) { return o.name === 'cap-pattern' })
        const cap = _.find(this.canvas._objects, function (o) { return o.name === 'cap' })
        const overlay = _.find(this.canvas._objects, function (o) { return o.name === 'cap-pattern-overlay' })
        const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'capColourText' })
        const text2 = _.find(this.canvas._objects, function (o) { return o.name === 'capPatternText' })
        const text3 = _.find(this.canvas._objects, function (o) { return o.name === 'capPatternColourText' })
        this.canvas.remove(capPattern, cap, text1, text2, text3)
        if (overlay) this.canvas.remove(overlay)
        this.mobilePreview()
      }
    }
    else if (e.target.name === 'jacket-check') {
      this.setState({ jacketCheck: e.target.checked }, () => {
        if (!this.state.capCheck && !this.state.jacketCheck && !this.state.blinkersCheck) this.canvas.add(text)
      })
      if (e.target.checked) {
        fabric.Image.fromURL('/assets/images/customdesign/shirt/shirt1/Plain.png', (img) => {
          img.set({
            top: 220,
            left: 100,
            name: 'shirt'

          })
          img.scaleToHeight(80)
          img.scaleToWidth(80)
          this.canvas.add(img);
        });

        fabric.Image.fromURL('/assets/images/customdesign/shirt/shirt1/plain-shirt.png', (img) => {
          img.set({
            top: 220,
            left: 100,
            name: 'shirt-pattern'

          })
          img.scaleToHeight(80)
          img.scaleToWidth(80)
          this.canvas.add(img);
        });

        fabric.Image.fromURL('/assets/images/customdesign/sleevers/sleevers_r/plain-sleever-white.png', (img) => {
          img.set({
            top: 243,
            left: 175,
            name: 'sleeve-right'
          })
          img.scaleToHeight(50)
          img.scaleToWidth(50)
          this.canvas.add(img);
        })
        fabric.Image.fromURL('/assets/images/customdesign/sleevers/sleevers_l/plain-sleevel-white.png', (img) => {
          img.set({
            top: 243,
            left: 55,
            name: 'sleeve-left'
          })
          img.scaleToHeight(50)
          img.scaleToWidth(50)
          this.canvas.add(img);
        })

        fabric.Image.fromURL('/assets/images/customdesign/sleevers/sleevers_l/plain-sleevel.png', (img) => {
          img.set({
            top: 243,
            left: 55,
            name: 'sleeve-left-pattern'
          })
          img.scaleToHeight(50)
          img.scaleToWidth(50)
          this.canvas.add(img)
        })

        fabric.Image.fromURL('/assets/images/customdesign/sleevers/sleevers_r/plain-sleever.png', (img) => {
          img.set({
            top: 243,
            left: 175,
            name: 'sleeve-right-pattern'
          })
          img.scaleToHeight(50)
          img.scaleToWidth(50)
          this.canvas.add(img)
         
        })

        setTimeout(() => {
          this.mobilePreview()
        }, 200);

      }
      else if (!e.target.checked) {
        const shirtPattern = _.find(this.canvas._objects, function (o) { return o.name === 'shirt-pattern' })
        const shirt = _.find(this.canvas._objects, function (o) { return o.name === 'shirt' })
        const shirtOverlay = _.find(this.canvas._objects, function (o) { return o.name === 'shirt-pattern-overlay' })

        const sleeveRPattern = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-right-pattern' })
        const sleeveR = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-right' })
        const sleeveROverlay = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-right-pattern-overlay' })

        const sleeveLPattern = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-left-pattern' })
        const sleeveL = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-left' })
        const sleeveLOverlay = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-left-pattern-overlay' })
        const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'jacketColourText' })
        const text2 = _.find(this.canvas._objects, function (o) { return o.name === 'jacketPatternText' })
        const text3 = _.find(this.canvas._objects, function (o) { return o.name === 'jacketPatternColourText' })
        const text4 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeveColourText' })
        const text5 = _.find(this.canvas._objects, function (o) { return o.name === 'sleevePatternText' })
        const text6 = _.find(this.canvas._objects, function (o) { return o.name === 'sleevePatternColourText' })
        this.canvas.remove(shirtPattern, shirt, sleeveRPattern, sleeveR, sleeveL, sleeveLPattern, text1, text2, text3, text4, text5, text6)
        if (shirtOverlay) this.canvas.remove(shirtOverlay)
        if (sleeveROverlay) this.canvas.remove(sleeveROverlay)
        if (sleeveLOverlay) this.canvas.remove(sleeveLOverlay)
        this.mobilePreview()
      }
    }
    else if (e.target.name === 'blinkers-check') {
      this.setState({ blinkersCheck: e.target.checked }, () => {
        if (!this.state.capCheck && !this.state.jacketCheck && !this.state.blinkersCheck) this.canvas.add(text)
      })
      if (e.target.checked) {
        fabric.Image.fromURL('/assets/images/customdesign/blinkers/blinker1/plain-white.png', (img) => {
          img.set({
            top: 370,
            left: 70,
            name: 'blinkers'
          })
          img.scaleToHeight(140)
          img.scaleToWidth(140)
          this.canvas.add(img);
          this.mobilePreview()
        });

        fabric.Image.fromURL('/assets/images/customdesign/blinkers/blinker1/Plain.png', (img) => {
          img.set({
            top: 370,
            left: 70,
            name: 'blinkers-pattern'
          })
          img.scaleToHeight(140)
          img.scaleToWidth(140)
          this.canvas.add(img);
        });
      }
      else if (!e.target.checked) {
        const blinkersPattern = _.find(this.canvas._objects, function (o) { return o.name === 'blinkers-pattern' })
        const blinkers = _.find(this.canvas._objects, function (o) { return o.name === 'blinkers' })
        const blinkersOverlay = _.find(this.canvas._objects, function (o) { return o.name === 'blinkers-pattern-overlay' })
        const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'blinkerColourText' })
        const text2 = _.find(this.canvas._objects, function (o) { return o.name === 'blinkerPatternText' })
        const text3 = _.find(this.canvas._objects, function (o) { return o.name === 'blinkerPatternColourText' })
        this.canvas.remove(blinkers, blinkersPattern, text1, text2, text3)
        if (blinkersOverlay) this.canvas.remove(blinkersOverlay)
        this.mobilePreview()
      }
    }
  }

  capPattern = (e) => {
    if (!this.state.capCheck) return
    let object = _.find(this.canvas._objects, function (o) { return o.name === 'cap-pattern' })
    if (object) this.canvas.remove(object)
    this.setState({ capPattern: e.target.name }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'capPatternText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Pattern: ${this.state.capPattern}`,
        {
          left: 5,
          top: 600,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'capPatternText'
        });
      this.canvas.add(text)
    })
    fabric.Image.fromURL(e.target.src, (img) => {
      img.set({
        top: 100,
        left: 100,
        name: 'cap-pattern'
      })
      img.scaleToHeight(100)
      img.scaleToWidth(100)
      this.canvas.add(img)
      this.mobilePreview()
    });

  }
  capPatternColor = (e) => {
    if (!this.state.capCheck) return
    let object = _.find(this.canvas._objects, function (o) { return o.name === 'cap-pattern' })
    let overlay = _.find(this.canvas._objects, function (o) { return o.name === 'cap-pattern-overlay' })
    if (overlay) this.canvas.remove(overlay)
    this.setState({ capPatternColour: this.getPatternColourName(e.hex) }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'capPatternColourText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Pattern Colour: ${this.state.capPatternColour}`,
        {
          left: 5,
          top: 620,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'capPatternColourText'
        });
      this.canvas.add(text)
    })
    fabric.Image.fromURL('/assets/images/customdesign/cap/cap1/Plain_Cap.png', (img) => {
      img.set({
        top: 100,
        left: 100,
        name: 'cap-pattern-overlay'
      })
      img.scaleToHeight(100)
      img.scaleToWidth(100)
      this.canvas.add(img);
      this.mobilePreview()
    })
    const filter = new fabric.Image.filters.BlendColor({
      color: e.hex,
      mode: 'tint',
      alpha: 1.0
    });
    object.filters.push(filter)
    object.applyFilters()
    this.canvas.renderAll()

  }
  capColour = (e) => {
    if (!this.state.capCheck) return
    let object = _.find(this.canvas._objects, function (o) { return o.name === 'cap' })
    let color = e.target.getAttribute('color')
    this.setState({ capColour: e.target.name }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'capColourText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Cap Colour: ${this.state.capColour}`,
        {
          left: 5,
          top: 580,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'capColourText'
        });
      this.canvas.add(text)
    })
    const filter = new fabric.Image.filters.BlendColor({
      color: color,
      mode: 'tint',
      alpha: 1.0
    });

    object.filters.push(filter)
    object.applyFilters()
    this.canvas.sendBackwards(object)
    this.canvas.renderAll();
    this.mobilePreview()
  }

  shirtColour = (e) => {
    if (!this.state.jacketCheck) return
    let object = _.find(this.canvas._objects, function (o) { return o.name === 'shirt' })
    let color = e.target.getAttribute('color')
    this.setState({ jacketColour: e.target.name }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'jacketColourText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Jacket Colour: ${this.state.jacketColour}`,
        {
          left: 5,
          top: 650,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'jacketColourText'
        });
      this.canvas.add(text)
    })
    const filter = new fabric.Image.filters.BlendColor({
      color: color,
      mode: 'tint',
      alpha: 1.0
    });
    object.filters.push(filter)
    object.applyFilters()
    this.canvas.sendBackwards(object)
    this.canvas.renderAll();
    this.mobilePreview()
  }

  shirtPattern = (e) => {
    if (!this.state.jacketCheck) return
    let object = _.find(this.canvas._objects, function (o) { return o.name === 'shirt-pattern' })
    if (object) this.canvas.remove(object)
    this.setState({ jacketPattern: e.target.name }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'jacketPatternText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Pattern: ${this.state.jacketPattern}`,
        {
          left: 5,
          top: 670,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'jacketPatternText'
        });
      this.canvas.add(text)
    })
    fabric.Image.fromURL(e.target.src, (img) => {
      img.set({
        top: 220,
        left: 100,
        name: 'shirt-pattern'
      })
      img.scaleToHeight(80)
      img.scaleToWidth(80)
      this.canvas.add(img);
      this.mobilePreview()
    })
  }

  shirtPatternColour = (e) => {
    if (!this.state.jacketCheck) return
    let object = _.find(this.canvas._objects, function (o) { return o.name === 'shirt-pattern' })
    let overlay = _.find(this.canvas._objects, function (o) { return o.name === 'shirt-pattern-overlay' })
    if (overlay) this.canvas.remove(overlay)
    this.setState({ jacketPatternColour: this.getPatternColourName(e.hex) }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'jacketPatternColourText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Pattern Colour: ${this.state.jacketPatternColour}`,
        {
          left: 5,
          top: 690,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'jacketPatternColourText'
        });
      this.canvas.add(text)
    })
    fabric.Image.fromURL('/assets/images/customdesign/shirt/shirt1/plain-shirt.png', (img) => {
      img.set({
        top: 220,
        left: 100,
        name: 'shirt-pattern-overlay'

      })
      img.scaleToHeight(80)
      img.scaleToWidth(80)
      this.canvas.add(img);
      this.mobilePreview()
    })
    const filter = new fabric.Image.filters.BlendColor({
      color: e.hex,
      mode: 'tint',
      alpha: 1.0
    });
    object.filters.push(filter)
    object.applyFilters()
    this.canvas.renderAll()
  }

  sleeveColour = (e) => {
    if (!this.state.jacketCheck) return
    let object1 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-right' })
    let object2 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-left' })
    let color = e.target.getAttribute('color')
    const filter = new fabric.Image.filters.BlendColor({
      color: color,
      mode: 'tint',
      alpha: 1.0
    });
    const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeveColourText' })
    if (text1) {
      this.canvas.remove(text1)
    }
    const text = new fabric.Text(`Sleeve Colour: ${e.target.getAttribute('name')}`,
      {
        left: 5,
        top: 720,
        fontSize: 13,
        textAlign: 'left',
        fontFamily: 'Roboto',
        name: 'sleeveColourText'
      });
    this.canvas.add(text)
    object1.filters.push(filter)
    object2.filters.push(filter)
    object1.applyFilters()
    object2.applyFilters()
    this.canvas.sendBackwards(object1)
    this.canvas.sendBackwards(object2)
    this.canvas.renderAll();
    this.mobilePreview()
  }

  sleevePattern = (e) => {
    if (!this.state.jacketCheck) return
    let object1 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-left-pattern' })
    let object2 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-right-pattern' })
    if (object1) this.canvas.remove(object1)
    if (object2) this.canvas.remove(object2)
    let src = e.target.src
    this.setState({ sleevesPattern: e.target.name }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'sleevePatternText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Pattern: ${this.state.sleevesPattern}`,
        {
          left: 5,
          top: 740,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'sleevePatternText'
        });
      this.canvas.add(text)
    })
    fabric.Image.fromURL(src, (img) => {
      img.set({
        top: 243,
        left: 55,
        name: 'sleeve-left-pattern'
      })
      img.scaleToHeight(50)
      img.scaleToWidth(50)
      this.canvas.add(img);
    })
    fabric.Image.fromURL(src.replace('.png', 'R.png'), (img) => {
      img.set({
        top: 243,
        left: 175,
        name: 'sleeve-right-pattern'
      })
      img.scaleToHeight(50)
      img.scaleToWidth(50)
      this.canvas.add(img);
      this.mobilePreview()
    })
  }
  sleevePatternColour = (e) => {
    if (!this.state.jacketCheck) return
    let object1 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-left-pattern' })
    let overlay1 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-left-pattern-overlay' })
    let object2 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-right-pattern' })
    let overlay2 = _.find(this.canvas._objects, function (o) { return o.name === 'sleeve-right-pattern-overlay' })
    if (overlay1) this.canvas.remove(overlay1)
    if (overlay2) this.canvas.remove(overlay2)
    this.setState({ sleevesPatternColour: this.getPatternColourName(e.hex) }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'sleevePatternColourText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Pattern Colour: ${this.state.sleevesPatternColour}`,
        {
          left: 5,
          top: 760,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'sleevePatternColourText'
        });
      this.canvas.add(text)
    })
    fabric.Image.fromURL('/assets/images/customdesign/sleevers/sleevers_l/plain-sleevel.png', (img) => {
      img.set({
        top: 243,
        left: 55,
        name: 'sleeve-left-pattern-overlay'
      })
      img.scaleToHeight(50)
      img.scaleToWidth(50)
      this.canvas.add(img);
    })
    fabric.Image.fromURL('/assets/images/customdesign/sleevers/sleevers_r/plain-sleever.png', (img) => {
      img.set({
        top: 243,
        left: 175,
        name: 'sleeve-right-pattern-overlay'
      })
      img.scaleToHeight(50)
      img.scaleToWidth(50)
      this.canvas.add(img);
      this.mobilePreview()
    })
    const filter = new fabric.Image.filters.BlendColor({
      color: e.hex,
      mode: 'tint',
      alpha: 1.0
    });
    object1.filters.push(filter)
    object1.applyFilters()
    object2.filters.push(filter)
    object2.applyFilters()
    this.canvas.renderAll()
  }

  blinkerColour = (e) => {
    if (!this.state.blinkersCheck) return
    let object = _.find(this.canvas._objects, function (o) { return o.name === 'blinkers' })
    let color = e.target.getAttribute('color')
    this.setState({ blinkersColour: e.target.name }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'blinkerColourText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Blinkers Colour: ${this.state.blinkersColour}`,
        {
          left: 180,
          top: 580,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'blinkerColourText'
        });
      this.canvas.add(text)
    })
    const filter = new fabric.Image.filters.BlendColor({
      color: color,
      mode: 'tint',
      alpha: 1.0
    });
    object.filters.push(filter)
    object.applyFilters()
    this.canvas.sendBackwards(object)
    this.canvas.renderAll();
    this.mobilePreview()
  }

  blinkerPattern = (e) => {
    if (!this.state.blinkersCheck) return
    let object = _.find(this.canvas._objects, function (o) { return o.name === 'blinkers-pattern' })
    if (object) this.canvas.remove(object)
    this.setState({ blinkersPattern: e.target.name }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'blinkerPatternText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Pattern: ${this.state.blinkersPattern}`,
        {
          left: 180,
          top: 600,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'blinkerPatternText'
        });
      this.canvas.add(text)
    })
    fabric.Image.fromURL(e.target.src, (img) => {
      img.set({
        top: 370,
        left: 70,
        name: 'blinkers-pattern'

      })
      img.scaleToHeight(140)
      img.scaleToWidth(140)
      this.canvas.add(img)
      this.mobilePreview()
    })
  }

  blinkerPatternColour = (e) => {
    if (!this.state.blinkersCheck) return
    let object = _.find(this.canvas._objects, function (o) { return o.name === 'blinkers-pattern' })
    let overlay = _.find(this.canvas._objects, function (o) { return o.name === 'blinkers-pattern-overlay' })
    if (overlay) this.canvas.remove(overlay)
    this.setState({ blinkersPatternColour: this.getPatternColourName(e.hex) }, () => {
      const text1 = _.find(this.canvas._objects, function (o) { return o.name === 'blinkerPatternColourText' })
      if (text1) {
        this.canvas.remove(text1)
      }
      const text = new fabric.Text(`Pattern Colour: ${this.state.blinkersPatternColour}`,
        {
          left: 180,
          top: 620,
          fontSize: 13,
          textAlign: 'left',
          fontFamily: 'Roboto',
          name: 'blinkerPatternColourText'
        });
      this.canvas.add(text)
    })
    fabric.Image.fromURL('/assets/images/customdesign/blinkers/blinker1/Plain.png', (img) => {
      img.set({
        top: 370,
        left: 70,
        name: 'blinkers-pattern-overlay'

      })
      img.scaleToHeight(140)
      img.scaleToWidth(140)
      this.canvas.add(img)
      this.mobilePreview()
    })
    const filter = new fabric.Image.filters.BlendColor({
      color: e.hex,
      mode: 'tint',
      alpha: 1.0
    });
    object.filters.push(filter)
    object.applyFilters()
    this.canvas.renderAll()
  }

  getPatternColourName = (colour) => {
    const colourNames = ["Apricot", "Aqua", "Black", "Bottle", "Brown", "Cerise", "Charcoal", "Cream", "Emerald", "Flouro Green", "Flouro Orange", "Flouro Pink", "Flouro Yellow", "Gold", "Hot Pink", "Lime", "Maroon", "Mauve", "Navy", "Old Gold", "Orange", "Pale Blue", "Pale Pink", "Purple", "Red", "Royal Blue", "Salmon", "Silver", "White", "Yellow"]
    const colourIndex = this.state.pickerColors.indexOf(colour)
    return colourNames[colourIndex]
  }

  saveCanvas = (e) => {
    const img = document.querySelector('#main-canvas').toDataURL(); //attempt to save base64 string to server using this var  
    const product = $.extend(true, {}, this.props.products.find((p) => p.id == 40))
    product.id = this.props.products[this.props.products.length - 1].id + 1
    product.url = product.url + " "
    console.log('In save Canvas')

    if (this.state.capCheck && this.state.jacketCheck && this.state.blinkersCheck) {
      product.price = 335.00
      product.name = 'Custom Jockey Colours + Blinkers'
      product.capColour = this.state.capColour
      product.jacketColour = this.state.jacketColour
      product.blinkersColour = this.state.blinkersColour
      product.capPattern = this.state.capPattern
      product.capPatternColour = this.state.capPatternColour
      product.jacketPattern = this.state.jacketPattern
      product.jacketPatternColour = this.state.jacketPatternColour
      product.blinkersPattern = this.state.blinkersPattern
      product.blinkersPatternColour = this.state.blinkersPatternColour
    }
    else if (this.state.capCheck && this.state.jacketCheck) {
      product.price = 210.00
      product.name = 'Custom Jockey Colours (Jacket + Cap)'
      product.capColour = this.state.capColour
      product.jacketColour = this.state.jacketColour
      product.capPattern = this.state.capPattern
      product.capPatternColour = this.state.capPatternColour
      product.jacketPattern = this.state.jacketPattern
      product.jacketPatternColour = this.state.jacketPatternColour
    }
    else if (this.state.blinkersCheck) {
      product.price = 125.00
      product.name = 'Custom Horse Blinkers'
      product.blinkersColour = this.state.blinkersColour
      product.blinkersPattern = this.state.blinkersPattern
      product.blinkersPatternColour = this.state.blinkersPatternColour
    }
    product.customDesign = img
    product.pictures = [img]
    product.variants[0].images = img
    this.props.products.push(product)
    this.props.addToCart(product, 1)
  }

  printToPdf = () => {
    let mittysLogo = new Image()
    mittysLogo.src = '/assets/images/icon/logo.png'
    const dataUrl = document.querySelector('#main-canvas').toDataURL();
    const date = moment().format('DD/MM/YYYY')
    const footer = "© Mittys 2018 - 2019"
    const pdf = new jsPDF("p", "mm", "a4");
    console.log(dataUrl)
    pdf.setFontSize(20)
    pdf.text('Mittys Custom Design', 70, 25)
    pdf.setFontSize(10)
    pdf.text(date, 175, 12)
    pdf.setFontSize(12)
    pdf.text('Reference:  ######', 165, 20)
    pdf.setFontSize(14)
    if (this.state.capCheck && this.state.jacketCheck) {
      pdf.text('Jockey Colours (Cap + Shirt)', 100, 60)
      pdf.text('$210.00', 100, 70)
      pdf.setFontSize(10)
      pdf.text(`- Cap Colour: ${this.state.capColour}`, 100, 80)
      pdf.text(`- Cap Pattern: ${this.state.capPattern}, Pattern Colour: ${this.state.capPatternColour}`, 100, 90)
      pdf.text(`- Jacket Colour: ${this.state.jacketColour}`, 100, 100)
      pdf.text(`- Jacket Pattern: ${this.state.jacketPattern}, Pattern Colour: ${this.state.jacketPatternColour}`, 100, 110)
    }
    else if (this.state.capCheck) {
      pdf.text('Jockey Colours (Cap)', 100, 60)
      pdf.text('$42.50', 100, 70)
      pdf.setFontSize(10)
      pdf.text(`- Cap Colour: ${this.state.capColour}`, 100, 80)
      pdf.text(`- Cap Pattern: ${this.state.capPattern}, Pattern Colour: ${this.state.capPatternColour}`, 100, 90)
    }
    if (this.state.blinkersCheck) {
      pdf.setFontSize(14)
      pdf.text('Blinkers', 100, 140)
      pdf.text('$125.00', 100, 150)
      pdf.setFontSize(10)
      pdf.text(`- Blinkers Colour: ${this.state.blinkersColour}`, 100, 160)
      pdf.text(`- Blinkers Pattern: ${this.state.blinkersPattern}, Pattern Colour: ${this.state.blinkersPatternColour}`, 100, 170)

    }
    pdf.addImage(mittysLogo, 'png', 10, 5)
    pdf.addImage(dataUrl, 'png', 5, 30, this.canvas.width * 0.3, this.canvas.height * 0.3)
    pdf.setFontSize(8)
    pdf.text(footer, 92, pdf.internal.pageSize.height - 5)
    // pdf.save('customdesign')
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      let blob = pdf.output('blob');
      window.open(URL.createObjectURL(blob), "_blank");
    }
    else {
      pdf.save('customdesign.pdf');
    }
  }

  colorHover = (color, event) => {
    let name = this.getPatternColourName(color.hex)
    event.target.title = name
  }

  selectTab = (index) => {
    this.setState({ selectedTab: index }, () => {
      this.mobilePreview()
    })
  }

  removeTextForMobile = () => {
    this.mobileCanvas.remove(this.mobileCanvas._objects[0])
  }

  mobilePreview = () => {
    let tab = this.state.selectedTab
    // let display = ''
    switch (tab) {
      case 0:
        let point = new fabric.Point(0, 50)
        this.mobileCanvas.clear()
        let display = this.canvas.toJSON()
        this.mobileCanvas.loadFromJSON(JSON.stringify(display))
        this.removeTextForMobile()
        this.mobileCanvas.absolutePan(point)
        break;
      case 1:
        point = new fabric.Point(0, 185)
        this.mobileCanvas.clear()
        display = this.canvas.toJSON()
        this.mobileCanvas.loadFromJSON(JSON.stringify(display))
        this.removeTextForMobile()
        this.mobileCanvas.absolutePan(point)
        break;
      case 2:
        point = new fabric.Point(0, 185)
        this.mobileCanvas.clear()
        display = this.canvas.toJSON()
        this.mobileCanvas.loadFromJSON(JSON.stringify(display))
        this.removeTextForMobile()
        this.mobileCanvas.absolutePan(point)
        break;
      case 3:
        point = new fabric.Point(0, 355)
        this.mobileCanvas.clear()
        display = this.canvas.toJSON()
        this.mobileCanvas.loadFromJSON(JSON.stringify(display))
        this.removeTextForMobile()
        this.mobileCanvas.absolutePan(point)
        break;
    }
  }


  render() {
    // store.dispatch(getAllProducts());
    const { addToCart, products } = this.props;
    return (
      <div className="main-canvas-container container text-center">
        <div className="page-main-content" >
          <div className="top-banner-content small-section custom-padding">
            <h3 style={{ color: '#df1234' }}>DESIGN A CUSTOM JOCKEY OUTFIT AT OUR JOCKEY ONLINE STORE</h3>
            <br />
            <p>At Mittys, we allow you to create and design your own jockey outfit and jockey colours in accordance with your specific needs and preferences. Using our simple system, you can choose the colours and patterns you want for your cap, jacket, sleeves, and custom horse blinkers. From red coloured caps featuring star patterns to light blue jackets featuring shamrocks, there's no shortage of customisation options available for you to choose from thanks to our fantastic jockey online store.</p>
            <br />
            <h3 style={{ color: '#df1234' }}>WHY DESIGN JOCKEY COLOURS WITH MITTYS?</h3>
            <br />
            <p>Mittys has developed a reputation for manufacturing and supplying the highest quality racing colours. Our custom jockey colours are made from ultra-lightweight materials, helping to enhance your aerodynamic performance and give you the winning edge you need. They are also tough and durable, featuring double stitched seams and remarkably strong threads. You can trust that our colours will stay intact as well as maintain their brightness for years to come without any fading.</p>
            <p>Our jockey outfits use scientifically proven techniques to provide comfortable temperatures throughout the year for jockeys. This means that you can feel cool during the summer and warm during the winter, helping to minimise jockey fatigue and potentially make the difference between winning and losing. Our custom horse blinkers and jockey colours are also machine washable, drip dryable, and colourfast.</p>
          </div>
        </div>
        <div className="container">
          <div className="row custom-row">
            <div className="col-sm-12 design-col">
              <div className="jumbotron custom-jumbotron design-jumbotron">
                <canvas id='main-canvas'>
                </canvas>
              </div>
              <div className="jumbotron custom-jumbotron">
                <Tabs selectedIndex={this.state.selectedTab} onSelect={() => { console.log('Selected') }}>
                  <TabList>
                    <Tab onClick={() => this.selectTab(0)}>1. Cap</Tab>
                    <Tab onClick={() => this.selectTab(1)}>2. Jacket</Tab>
                    <Tab onClick={() => this.selectTab(2)}>3. Sleeves</Tab>
                    <Tab onClick={() => this.selectTab(3)} key={3}>4. Blinkers</Tab>
                  </TabList>
                  <TabPanel>
                    <div>
                      <h4>1. Would you like to select a cap?</h4>
                      <label>
                        <Switch id="cap-check" checked={this.state.capCheck} onChange={this.addToCanvas} onColor={"#da1f3d"} />
                      </label>
                    </div>
                    <div className="image-container">
                      <h4>2. Please Choose Your Cap Colour</h4>
                      <ul>
                        <li><img onClick={this.capColour} color="#f9c9bb" name="Apricot" src="/assets/images/customdesign/apricot.png"></img></li>
                        <li><img onClick={this.capColour} color="#009ee3" name="Aqua" src="/assets/images/customdesign/aqua.png"></img></li>
                        <li><img onClick={this.capColour} color="black" name="Black" src="/assets/images/customdesign/black.png"></img></li>
                        <li><img onClick={this.capColour} color="#006551" name="Bottle" src="/assets/images/customdesign/bottle.png"></img></li>
                        <li><img onClick={this.capColour} color="#4a1f00" name="Brown" src="/assets/images/customdesign/brown.png"></img></li>
                        <li><img onClick={this.capColour} color="#e72a77" name="Cerise" src="/assets/images/customdesign/cerise.png"></img></li>
                        <li><img onClick={this.capColour} color="#5f5f5e" name="Charcoal" src="/assets/images/customdesign/charcoal.png"></img></li>
                        <li><img onClick={this.capColour} color="#fff9d3" name="Cream" src="/assets/images/customdesign/cream.png"></img></li>
                        <li><img onClick={this.capColour} color="#00976e" name="Emerald" src="/assets/images/customdesign/emerald.png"></img></li>
                        <li><img onClick={this.capColour} color="#3aa935" name="Flouro Green" src="/assets/images/customdesign/flouro-green.png"></img></li>
                        <li><img onClick={this.capColour} color="#ec6607" name="Flouro Orange" src="/assets/images/customdesign/flouro-orange.png"></img></li>
                        <li><img onClick={this.capColour} color="#e14190" name="Flouro Pink" src="/assets/images/customdesign/flouro-pink.png"></img></li>
                        <li><img onClick={this.capColour} color="#ffe800" name="Flouro Yellow" src="/assets/images/customdesign/flouro-yello.png"></img></li>
                        <li><img onClick={this.capColour} color="#fecb26" name="Gold" src="/assets/images/customdesign/glod.png"></img></li>
                        <li><img onClick={this.capColour} color="#ef84ad" name="Hot Pink" src="/assets/images/customdesign/hot-pink.png"></img></li>
                        <li><img onClick={this.capColour} color="#6bb534" name="Lime" src="/assets/images/customdesign/lime.png"></img></li>
                        <li><img onClick={this.capColour} color="#842d47" name="Maroon" src="/assets/images/customdesign/maroon.png"></img></li>
                        <li><img onClick={this.capColour} color="#8b7ab7" name="Mauve" src="/assets/images/customdesign/mauve.png"></img></li>
                        <li><img onClick={this.capColour} color="#0f1b44" name="Navy" src="/assets/images/customdesign/navy.png"></img></li>
                        <li><img onClick={this.capColour} color="#fbbf58" name="Old Gold" src="/assets/images/customdesign/old-gold.png"></img></li>
                        <li><img onClick={this.capColour} color="#ec6b05" name="Orange" src="/assets/images/customdesign/orange.png"></img></li>
                        <li><img onClick={this.capColour} color="#009ee3" name="Pale Blue" src="/assets/images/customdesign/pale-blue.png"></img></li>
                        <li><img onClick={this.capColour} color="#f099c1" name="Pale Pink" src="/assets/images/customdesign/pale-pink.png"></img></li>
                        <li><img onClick={this.capColour} color="#302783" name="Purple" src="/assets/images/customdesign/purple.png"></img></li>
                        <li><img onClick={this.capColour} color="#dd0332" name="Red" src="/assets/images/customdesign/red.png"></img></li>
                        <li><img onClick={this.capColour} color="#26358b" name="Royal Blue" src="/assets/images/customdesign/royal-blue.png"></img></li>
                        <li><img onClick={this.capColour} color="#f6b0a0" name="Salmon" src="/assets/images/customdesign/salmon.png"></img></li>
                        <li><img onClick={this.capColour} color="#b0b2b4" name="Silver" src="/assets/images/customdesign/silver.png"></img></li>
                        <li><img onClick={this.capColour} color="#ffffff" name="White" src="/assets/images/customdesign/white.png"></img></li>
                        <li><img onClick={this.capColour} color="#ffed00" name="Yellow" src="/assets/images/customdesign/yellow.png"></img></li>
                      </ul>
                    </div>
                    <div className="image-container">

                      <h4>3. Please choose your Cap Pattern</h4>
                      <ul>
                        <li><img onClick={this.capPattern} name="Checks" src="/assets/images/customdesign/cap/Checks.png"></img></li>
                        <li><img onClick={this.capPattern} name="Diamond Checks" src="/assets/images/customdesign/cap/Diamond Checks.png"></img></li>
                        <li><img onClick={this.capPattern} name="Diamonds" src="/assets/images/customdesign/cap/Diamonds.png"></img></li>
                        <li><img onClick={this.capPattern} name="Hoops" src="/assets/images/customdesign/cap/Hoops.png"></img></li>
                        <li><img onClick={this.capPattern} name="Plain" src="/assets/images/customdesign/cap/Plain Cap.png"></img></li>
                        <li><img onClick={this.capPattern} name="Plain with Seams" src="/assets/images/customdesign/cap/Plain with Seams.png"></img></li>
                        <li><img onClick={this.capPattern} name="Quarters" src="/assets/images/customdesign/cap/Quarters.png"></img></li>
                        <li><img onClick={this.capPattern} name="Random Spots" src="/assets/images/customdesign/cap/Random Spots.png"></img></li>
                        <li><img onClick={this.capPattern} name="Random Stars" src="/assets/images/customdesign/cap/Random Stars.png"></img></li>
                        <li><img onClick={this.capPattern} name="Spot" src="/assets/images/customdesign/cap/Spot.png"></img></li>
                        <li><img onClick={this.capPattern} name="Spots" name="Spots" src="/assets/images/customdesign/cap/Spots.png"></img></li>
                        <li><img onClick={this.capPattern} name="Stars" src="/assets/images/customdesign/cap/Stars.png"></img></li>
                        <li><img onClick={this.capPattern} name="Stripes" src="/assets/images/customdesign/cap/Stripes.png"></img></li>
                      </ul>
                    </div>
                    <div className="image-box">
                      <h4>4. Pick your Pattern Colour</h4>
                      <GithubPicker width="263px" onSwatchHover={this.colorHover} key={"cap-picker"} colors={this.state.pickerColors} className='colour-picker' onChangeComplete={this.capPatternColor} />
                    </div>
                    <button className="btn btn-solid btn-margin" onClick={() => this.selectTab(1)}>Next</button>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      <h4>1. Would you like to select a Jacket?</h4>
                      <label>
                        <Switch id="jacket-check" checked={this.state.jacketCheck} onChange={this.addToCanvas} onColor={"#da1f3d"} />
                      </label>
                    </div>
                    <div className="image-container">
                      <h4>2. Please Choose Your Jacket Colour</h4>
                      <ul>
                        <li><img onClick={this.shirtColour} color="#f9c9bb" name="Apricot" src="/assets/images/customdesign/apricot.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#009ee3" name="Aqua" src="/assets/images/customdesign/aqua.png"></img></li>
                        <li><img onClick={this.shirtColour} color="black" name="Black" src="/assets/images/customdesign/black.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#006551" name="Bottle" src="/assets/images/customdesign/bottle.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#4a1f00" name="Brown" src="/assets/images/customdesign/brown.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#e72a77" name="Cerise" src="/assets/images/customdesign/cerise.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#5f5f5e" name="Charcoal" src="/assets/images/customdesign/charcoal.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#fff9d3" name="Cream" src="/assets/images/customdesign/cream.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#00976e" name="Emerald" src="/assets/images/customdesign/emerald.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#3aa935" name="Flouro Green" src="/assets/images/customdesign/flouro-green.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#ec6607" name="Flouro Orange" src="/assets/images/customdesign/flouro-orange.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#e14190" name="Flouro Pink" src="/assets/images/customdesign/flouro-pink.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#ffe800" name="Flouro Yellow" src="/assets/images/customdesign/flouro-yello.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#fecb26" name="Gold" src="/assets/images/customdesign/glod.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#ef84ad" name="Hot Pink" src="/assets/images/customdesign/hot-pink.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#6bb534" name="Lime" src="/assets/images/customdesign/lime.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#842d47" name="Maroon" src="/assets/images/customdesign/maroon.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#8b7ab7" name="Mauve" src="/assets/images/customdesign/mauve.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#0f1b44" name="Navy" src="/assets/images/customdesign/navy.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#fbbf58" name="Old Gold" src="/assets/images/customdesign/old-gold.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#ec6b05" name="Orange" src="/assets/images/customdesign/orange.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#009ee3" name="Pale Blue" src="/assets/images/customdesign/pale-blue.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#f099c1" name="Pale Pink" src="/assets/images/customdesign/pale-pink.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#302783" name="Purple" src="/assets/images/customdesign/purple.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#dd0332" name="Red" src="/assets/images/customdesign/red.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#26358b" name="Royal Blue" src="/assets/images/customdesign/royal-blue.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#f6b0a0" name="Salmon" src="/assets/images/customdesign/salmon.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#b0b2b4" name="Silver" src="/assets/images/customdesign/silver.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#ffffff" name="White" src="/assets/images/customdesign/white.png"></img></li>
                        <li><img onClick={this.shirtColour} color="#ffed00" name="Yellow" src="/assets/images/customdesign/yellow.png"></img></li>
                      </ul>
                    </div>
                    <div className="image-container">
                      <h4>3. Please Choose Your Jacket Pattern</h4>
                      <ul>
                        <li><img onClick={this.shirtPattern} name="Band" src="/assets/images/customdesign/shirt/Band.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Braces" src="/assets/images/customdesign/shirt/Braces.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Centre Stripe" src="/assets/images/customdesign/shirt/Centre Stripe.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Checks" src="/assets/images/customdesign/shirt/Checks.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Chevrons" src="/assets/images/customdesign/shirt/Chevrons 3.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Clover" src="/assets/images/customdesign/shirt/Clover.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Cross of Lorraine" src="/assets/images/customdesign/shirt/Cross of Lorraine.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Cross Sashes" src="/assets/images/customdesign/shirt/Cross Sashes.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Cross" src="/assets/images/customdesign/shirt/Cross.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Diablo" src="/assets/images/customdesign/shirt/Diablo.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Diagonal Stripes" src="/assets/images/customdesign/shirt/Diagonal Stripes.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Diamond Band" src="/assets/images/customdesign/shirt/Diamond Band.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Diamond Checks" src="/assets/images/customdesign/shirt/Diamond Checks.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Diamond Sash" src="/assets/images/customdesign/shirt/Diamond Sash.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Diamond" src="/assets/images/customdesign/shirt/Diamond.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Diamonds" src="/assets/images/customdesign/shirt/Diamonds 5.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Epaulettes" src="/assets/images/customdesign/shirt/Epaulettes.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Halves Horizontal" src="/assets/images/customdesign/shirt/Halves Horizontal.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Hoops" src="/assets/images/customdesign/shirt/Hoops.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Horse Shoe" src="/assets/images/customdesign/shirt/Horse Shoe.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Horse Shoes" src="/assets/images/customdesign/shirt/Horse Shoes 5.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Inverted Triangle" src="/assets/images/customdesign/shirt/Inverted Triangle.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Maltese Cross" src="/assets/images/customdesign/shirt/Maltese Cross.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Plain" src="/assets/images/customdesign/shirt/Plain.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Quarters" src="/assets/images/customdesign/shirt/Quarters.png"></img></li>
                        <li><img style={{ height: '73px' }} onClick={this.shirtPattern} name="Random Spots" src="/assets/images/customdesign/shirt/Randon Spots.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Random Stars" src="/assets/images/customdesign/shirt/Randon Stars.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Sash" src="/assets/images/customdesign/shirt/Sash.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Seams" src="/assets/images/customdesign/shirt/Seams.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Shamrock" src="/assets/images/customdesign/shirt/Shamrock.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Spot" src="/assets/images/customdesign/shirt/Spot.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Spots" src="/assets/images/customdesign/shirt/Spots 5.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Spots Sash" src="/assets/images/customdesign/shirt/Spots Sash 3.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Square" src="/assets/images/customdesign/shirt/Square.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Star" src="/assets/images/customdesign/shirt/Star.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Stripes" src="/assets/images/customdesign/shirt/Stripes.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="V" src="/assets/images/customdesign/shirt/V.png"></img></li>
                        <li><img onClick={this.shirtPattern} name="Yoke" src="/assets/images/customdesign/shirt/Yoke.png"></img></li>
                      </ul>
                    </div>
                    <div className="image-box">
                      <h4>4. Pick your Pattern Colour</h4>
                      <GithubPicker width="263px" onSwatchHover={this.colorHover} key={"shirt-picker"} colors={this.state.pickerColors} className='colour-picker' onChangeComplete={this.shirtPatternColour} />
                    </div>
                    {/* <button className="btn btn-solid btn-margin" onClick={this.mobilePreview}>Preview</button> */}
                    <button className="btn btn-solid btn-margin" onClick={() => this.selectTab(2)}>Next</button>
                  </TabPanel>
                  <TabPanel>
                    <div className="image-container">
                      <h4>5. Please Choose Your Sleeve Colour</h4>
                      <ul>
                        <li><img onClick={this.sleeveColour} color="#f9c9bb" name="Apricot" src="/assets/images/customdesign/apricot.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#009ee3" name="Aqua" src="/assets/images/customdesign/aqua.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="black" name="Black" src="/assets/images/customdesign/black.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#006551" name="Bottle" src="/assets/images/customdesign/bottle.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#4a1f00" name="Brown" src="/assets/images/customdesign/brown.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#e72a77" name="Cerise" src="/assets/images/customdesign/cerise.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#5f5f5e" name="Charcoal" src="/assets/images/customdesign/charcoal.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#fff9d3" name="Cream" src="/assets/images/customdesign/cream.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#00976e" name="Emerald" src="/assets/images/customdesign/emerald.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#3aa935" name="Flouro Green" src="/assets/images/customdesign/flouro-green.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#ec6607" name="Flouro Orange" src="/assets/images/customdesign/flouro-orange.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#e14190" name="Flouro Pink" src="/assets/images/customdesign/flouro-pink.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#ffe800" name="Flouro Yellow" src="/assets/images/customdesign/flouro-yello.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#fecb26" name="Gold" src="/assets/images/customdesign/glod.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#ef84ad" name="Hot Pink" src="/assets/images/customdesign/hot-pink.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#6bb534" name="Lime" src="/assets/images/customdesign/lime.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#842d47" name="Maroon" src="/assets/images/customdesign/maroon.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#8b7ab7" name="Mauve" src="/assets/images/customdesign/mauve.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#0f1b44" name="Navy" src="/assets/images/customdesign/navy.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#fbbf58" name="Old Gold" src="/assets/images/customdesign/old-gold.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#ec6b05" name="Orange" src="/assets/images/customdesign/orange.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#009ee3" name="Pale Blue" src="/assets/images/customdesign/pale-blue.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#f099c1" name="Pale Pink" src="/assets/images/customdesign/pale-pink.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#302783" name="Purple" src="/assets/images/customdesign/purple.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#dd0332" name="Red" src="/assets/images/customdesign/red.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#26358b" name="Royal Blue" src="/assets/images/customdesign/royal-blue.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#f6b0a0" name="Salmon" src="/assets/images/customdesign/salmon.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#b0b2b4" name="Silver" src="/assets/images/customdesign/silver.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#ffffff" name="White" src="/assets/images/customdesign/white.png"></img></li>
                        <li><img onClick={this.sleeveColour} color="#ffed00" name="Yellow" src="/assets/images/customdesign/yellow.png"></img></li>
                      </ul>
                    </div>
                    <div className="image-container">
                      <h4>6. Please Choose Your Sleeve Pattern</h4>
                      <ul>
                        <li><img onClick={this.sleevePattern} name="Arm Band" src="/assets/images/customdesign/sleevers/Arm Band.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Checks" src="/assets/images/customdesign/sleevers/Checks.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Chevrons" src="/assets/images/customdesign/sleevers/Chevrons 3.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Diablo" src="/assets/images/customdesign/sleevers/Diablo.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Diagonals" src="/assets/images/customdesign/sleevers/Diagonals.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Diamond Arm Band" src="/assets/images/customdesign/sleevers/Diamond Arm Band.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Diamonds" src="/assets/images/customdesign/sleevers/Diamonds 3.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Diamond Checks" src="/assets/images/customdesign/sleevers/Diamonds Checks.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Halves" src="/assets/images/customdesign/sleevers/Halves.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Hoops" src="/assets/images/customdesign/sleevers/Hoops.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Plain" src="/assets/images/customdesign/sleevers/Plain.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Spots" src="/assets/images/customdesign/sleevers/Spots 3.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Stars" src="/assets/images/customdesign/sleevers/Stars 3.png"></img></li>
                        <li><img onClick={this.sleevePattern} name="Stripes" src="/assets/images/customdesign/sleevers/Stripes.png"></img></li>
                      </ul>
                    </div>
                    <div className="image-box">
                      <h4>7. Pick your Pattern Colour</h4>
                      <GithubPicker width="263px" onSwatchHover={this.colorHover} key={"sleeve-picker"} colors={this.state.pickerColors} className='colour-picker' onChangeComplete={this.sleevePatternColour} />
                    </div>
                    <button className="btn btn-solid btn-margin" onClick={() => this.selectTab(3)}>Next</button>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      <h4>1. Would you like to select Blinkers?</h4>
                      <label>
                        <Switch id="blinkers-check" checked={this.state.blinkersCheck} onChange={this.addToCanvas} onColor={"#da1f3d"} />
                      </label>
                    </div>
                    <div className="image-container">
                      <h4>2. Please Choose Your Blinkers Colour</h4>
                      <ul>
                        <li><img onClick={this.blinkerColour} color="#f9c9bb" name="Apricot" src="/assets/images/customdesign/apricot.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#009ee3" name="Aqua" src="/assets/images/customdesign/aqua.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="black" name="Black" src="/assets/images/customdesign/black.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#006551" name="Bottle" src="/assets/images/customdesign/bottle.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#4a1f00" name="Brown" src="/assets/images/customdesign/brown.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#e72a77" name="Cerise" src="/assets/images/customdesign/cerise.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#5f5f5e" name="Charcoal" src="/assets/images/customdesign/charcoal.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#fff9d3" name="Cream" src="/assets/images/customdesign/cream.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#00976e" name="Emerald" src="/assets/images/customdesign/emerald.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#3aa935" name="Flouro Green" src="/assets/images/customdesign/flouro-green.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#ec6607" name="Flouro Orange" src="/assets/images/customdesign/flouro-orange.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#e14190" name="Flouro Pink" src="/assets/images/customdesign/flouro-pink.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#ffe800" name="Flouro Yellow" src="/assets/images/customdesign/flouro-yello.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#fecb26" name="Gold" src="/assets/images/customdesign/glod.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#ef84ad" name="Hot Pink" src="/assets/images/customdesign/hot-pink.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#6bb534" name="Lime" src="/assets/images/customdesign/lime.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#842d47" name="Maroon" src="/assets/images/customdesign/maroon.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#8b7ab7" name="Mauve" src="/assets/images/customdesign/mauve.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#0f1b44" name="Navy" src="/assets/images/customdesign/navy.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#fbbf58" name="Old Gold" src="/assets/images/customdesign/old-gold.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#ec6b05" name="Orange" src="/assets/images/customdesign/orange.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#009ee3" name="Pale Blue" src="/assets/images/customdesign/pale-blue.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#f099c1" name="Pale Pink" src="/assets/images/customdesign/pale-pink.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#302783" name="Purple" src="/assets/images/customdesign/purple.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#dd0332" name="Red" src="/assets/images/customdesign/red.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#26358b" name="Royal Blue" src="/assets/images/customdesign/royal-blue.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#f6b0a0" name="Salmon" src="/assets/images/customdesign/salmon.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#b0b2b4" name="Silver" src="/assets/images/customdesign/silver.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#ffffff" name="White" src="/assets/images/customdesign/white.png"></img></li>
                        <li><img onClick={this.blinkerColour} color="#ffed00" name="Yellow" src="/assets/images/customdesign/yellow.png"></img></li>
                      </ul>
                    </div>
                    <div className="image-container">
                      <h4>3. Please Choose Your Blinkers Pattern</h4>
                      <ul className="blinker-photos">
                        <li><img onClick={this.blinkerPattern} name="Band" src="/assets/images/customdesign/blinkers/Band.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Braces" src="/assets/images/customdesign/blinkers/Braces.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Center Stripe" src="/assets/images/customdesign/blinkers/Center Stripe.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Checks" src="/assets/images/customdesign/blinkers/Checks.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Chevrons" src="/assets/images/customdesign/blinkers/Chevrons 3.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Clover" src="/assets/images/customdesign/blinkers/Clover.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Cross of Lorraine" src="/assets/images/customdesign/blinkers/Cross Of Lorraine.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Cross Sashes" src="/assets/images/customdesign/blinkers/Cross Sashes.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Diablo" src="/assets/images/customdesign/blinkers/Diablo.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Diagonal Stripes" src="/assets/images/customdesign/blinkers/Diagonal Stripes.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Diamond Band" src="/assets/images/customdesign/blinkers/Diamond Band.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Diamond Checks" src="/assets/images/customdesign/blinkers/Diamond Checks.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Diamond Sash" src="/assets/images/customdesign/blinkers/Diamond Sash.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Diamond" src="/assets/images/customdesign/blinkers/Diamond.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Diamonds" src="/assets/images/customdesign/blinkers/Diamonds 5.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Epaulettes" src="/assets/images/customdesign/blinkers/Epaulettes.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Halves Horizontal" src="/assets/images/customdesign/blinkers/Halves Horizontal.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Hoops" src="/assets/images/customdesign/blinkers/Hoops.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Horse Shoe" src="/assets/images/customdesign/blinkers/Horse Shoe.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Inverted Triangle" src="/assets/images/customdesign/blinkers/Inverted Triangle.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Maltese Cross" src="/assets/images/customdesign/blinkers/Maltese Cross.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Plain" src="/assets/images/customdesign/blinkers/Plain.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Quarters" src="/assets/images/customdesign/blinkers/Quarters.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Random Spots" src="/assets/images/customdesign/blinkers/Random Spots.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Random Stars" src="/assets/images/customdesign/blinkers/Random Stars.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Sash" src="/assets/images/customdesign/blinkers/Sash.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Shamrock" src="/assets/images/customdesign/blinkers/Shamrock.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Spot" src="/assets/images/customdesign/blinkers/Spot.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Spots Sash" src="/assets/images/customdesign/blinkers/Spots Sash 3.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Spots" src="/assets/images/customdesign/blinkers/Spots.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Square" src="/assets/images/customdesign/blinkers/Square.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Star" src="/assets/images/customdesign/blinkers/Star.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="Stripes" src="/assets/images/customdesign/blinkers/Stripes.png"></img></li>
                        <li><img onClick={this.blinkerPattern} name="V" src="/assets/images/customdesign/blinkers/V.png"></img></li>
                      </ul>
                    </div>
                    <div className="image-box">
                      <h4>4. Pick your Pattern Colour</h4>
                      <GithubPicker width="263px" onSwatchHover={this.colorHover} key={"blinker-picker"} colors={this.state.pickerColors} className='colour-picker' onChangeComplete={this.blinkerPatternColour} />
                      <br />
                      <button className="btn btn-solid btn-margin" onClick={this.saveCanvas.bind(this)}>Add To Cart</button>
                      <button className="btn btn-solid btn-margin" onClick={this.printToPdf}>PDF</button>
                    </div>
                  </TabPanel>
                </Tabs>
                <div className="mobile-preview-pane">
                  <canvas id='mobile-canvas'>
                  </canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div >
    )
  }
}

// export default CustomDesign;

const mapStateToProps = (state) => ({
  products: state.data.products,
  symbol: state.data.symbol,
})

export default connect(
  mapStateToProps, { addToCart, addToWishlist, addToCompare }
)(CustomDesign)