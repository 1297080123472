import React, { Component, Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import AuthService from '../../../../services/auth';

class TopBar extends Component {
 
    constructor(props) {
        super(props)
        this.state = {
            user: null
        }
        this.checkUser()
    }

    async checkUser() {
        const user = await AuthService.getUser();
        if (user !== this.state.user) {
            this.setState({ user: user.user });
        }
    }

    logout = (e) => {
        e.preventDefault()
        console.log(this.props)
        AuthService.signOut()
            .then(result => {
                this.setState({user: null}, () => this.props.history.push('/'))  
            })
            .catch(err => console.log(err))
    }

    render() {
        const { translate } = this.props;
        return (
            <div className="top-header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="header-contact">
                                <ul>
                                    <li>{translate('topbar_title', { theme_name: ' Multikart' })}</li>
                                    <li><i className="fa fa-phone" aria-hidden="true"></i>{translate('call_us')}: (03) 8413 6611</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 text-right">
                            <ul className="header-dropdown">
                                <li className="onhover-dropdown mobile-account">
                                    <i className="fa fa-user" aria-hidden="true"></i> {translate('my_account')}
                                    <ul className="onhover-show-div">
                                        {!this.state.user ?
                                            <Fragment>
                                                <li>
                                                    <Link to={`${process.env.PUBLIC_URL}/login`} data-lng="en">Login</Link>
                                                </li>
                                                <li>
                                                    <Link to={`${process.env.PUBLIC_URL}/register`} data-lng="en">Register</Link>
                                                </li>
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <li>
                                                    <Link to={{ pathname: '/dashboard', state: { user: this.state.user} }} data-lng="en">Dashboard</Link>
                                                </li>
                                                <li>
                                                    {/* <Link to={`${process.env.PUBLIC_URL}/logout`} data-lng="en">Logout</Link> */}
                                                    {/* <a onClick={this.logout}>Logout</a> */}
                                                </li>
                                            </Fragment>
                                        }

                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(TopBar);