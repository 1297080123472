import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'
import './index.scss';

// Amplify imports
import Amplify, { Auth, Analytics, Hub } from 'aws-amplify';
import awsconfig from './aws-exports';


// Import custom components
import store from './store';
import translations from './constants/translations'
import { getAllProducts } from './actions'

// Layouts
import Home from './components/layouts/mittys/main';
import Custom from './components/layouts/mittys/custom';
import UsefulLinks from './components/layouts/mittys/links';
import Questions from './components/layouts/mittys/questions';
import TalesGallery from './components/layouts/mittys/talesGallery';
import UploadForm from './components/upload/uploadForm';

// Product Pages
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import LeftSideBar from "./components/products/left-sidebar";

// Features
import Layout from './components/app'
import Cart from './components/cart'
import checkOut from './components/checkout'
import orderSuccess from './components/checkout/success-page'

// User Pages
import Login from './components/pages/login'
import Register from './components/pages/register'
import ForgetPassword from './components/pages/forget-password'
import DashBoard from './components/pages/dashboard'
import orderHistory from './components/pages/order-history';

class Root extends React.Component {

	state = { user: null }
	constructor(props) {
		super(props)
	}
	componentDidMount() {
		Hub.listen("auth", ({ payload: { event, data } }) => {
			switch (event) {
				case "signIn":
					this.setState({ user: data });
					break;
				case "signOut":
					this.setState({ user: null });
					break;
			}
		});

		Auth.currentAuthenticatedUser()
			.then(user => {
				this.setState({ user })
			})
			.catch(() => console.log("Not signed in"));

	}
	render() {
		store.dispatch(getAllProducts());

		return (
			<Provider store={store}>
				<IntlProvider translations={translations} locale='en'>
					<BrowserRouter basename={'/'} >
						<ScrollContext>
							<Switch>
								{/* Pages */}
								<Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
								<Route exact path={`${process.env.PUBLIC_URL}/custom`} component={Custom} />
								<Route exact path={`${process.env.PUBLIC_URL}/links`} component={UsefulLinks} />
								<Route exact path={`${process.env.PUBLIC_URL}/questions`} component={Questions} />

								<Layout history={this.props.history}>

									{/*Routes For User Layouts*/}
									<Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
									<Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />
									<Route path={`${process.env.PUBLIC_URL}/dashboard`} component={DashBoard} />
									<Route path={`${process.env.PUBLIC_URL}/order-history`} component={orderHistory} />
									<Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword} />
									{/*Routes For Product Layouts*/}
									<Route path={`${process.env.PUBLIC_URL}/products`} component={CollectionLeftSidebar} />
									<Route path={`${process.env.PUBLIC_URL}/product/:url`} component={LeftSideBar} />


									{/*Routes For custom Features*/}
									<Route path={`${process.env.PUBLIC_URL}/cart`} component={Cart} />
									<Route path={`${process.env.PUBLIC_URL}/checkout`} component={checkOut} />
									<Route path={`${process.env.PUBLIC_URL}/order-success`} component={orderSuccess} />
									<Route path={`${process.env.PUBLIC_URL}/horse-tales`} component={TalesGallery} />
									<Route path={`${process.env.PUBLIC_URL}/post/upload`} component={UploadForm} />

									{/* <Route exact component={PageNotFound} /> */}
								</Layout>
							</Switch>
						</ScrollContext>
					</BrowserRouter>
				</IntlProvider>
			</Provider>
		);
	}
}

Amplify.configure(awsconfig);
Analytics.disable()
ReactDOM.render(<Root />, document.getElementById('root'));


