import React, {Component} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.scss';
import {Link} from 'react-router-dom'

class DetailsTopTabs extends Component {
    render (){

        const item = this.props.item;

        return (
            <section className="tab-product m-0">
                <div className="row">
                    <div className="col-sm-12 col-lg-12">
                        <Tabs className="tab-content nav-material">
                            <TabList className="nav nav-tabs nav-material">
                                { (item.description != '') ? 
                                    <Tab className="nav-item">
                                        <span className="nav-link" ><i className="icofont icofont-man-in-glasses"></i>Details</span>
                                        <div className="material-border"></div>
                                    </Tab> : '' }
                            </TabList>
                            { (item.description != '') ?
                                <TabPanel>
                                    <p className="mt-4 p-0">
                                        {item.description}
                                    </p>
                                </TabPanel> : ''
                            }
                        </Tabs>
                    </div>
                </div>
            </section>
        )
    }
}

export default DetailsTopTabs;