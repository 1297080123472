import React, { Component, Fragment } from 'react';
import  CustomDesign  from '../common/customdesign';
import HeaderOne from "../../common/headers/header-one";
import FooterOne from "../../common/footers/footer-one";
import Breadcrumb from '../../common/breadcrumb';


class Custom extends Component {

  componentDidMount() {
    setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
  }

  render() {
    return (
      <Fragment>
        <HeaderOne history={this.props.history} />
        <Breadcrumb title={"Design"} />
        <CustomDesign />
        <FooterOne />
      </Fragment>
    )
  }
}

export default Custom;