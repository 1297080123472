import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import * as moment from 'moment';

class orderHistory extends Component {

  constructor(props) {
    super(props)
  }


  render() {

    const { order } = this.props.location.state;
    const symbol = '$'
    let subTotal = 0
    var deliver2Weeks = moment(order.date._d).add(14, 'days').format('dddd, MMMM Do YYYY');
    let deliveryDate = deliver2Weeks.toString()
    order.products.forEach((p) => {
      subTotal += p.sum
    })

    return (
      <Fragment>
        <Breadcrumb title={"Order " + order.number} parent="/dashboard" />
        {(order) ?

          <div>
            {/* <section className="section-b-space light-layout">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="success-text">
                      <i className="fa fa-check-circle" aria-hidden="true"></i>
                      <p>Order ID: {order.number ? order.number : null}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}

            <section className="section-b-space">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="product-order">
                      <h3>your order details</h3>
                      {order.products.map((item, index) => {
                        return <div className="row product-order-detail" key={index}>
                          <div className="col-3">
                            <img src={item.variants ?
                              item.variants[0].images
                              : item.pictures[0]} alt="" className="img-fluid" />
                          </div>
                          <div className="col-3 order_detail">
                            <div>
                              <h4>product name</h4>
                              <h5>{item.name}</h5>
                            </div>
                          </div>
                          <div className="col-3 order_detail">
                            <div>
                              <h4>quantity</h4>
                              <h5>{item.qty}</h5>
                            </div>
                          </div>
                          <div className="col-3 order_detail">
                            <div>
                              <h4>price</h4>
                              <h5>{symbol}{item.sum}</h5>
                            </div>
                          </div>
                        </div>
                      })}
                      <div className="total-sec">
                        <ul>
                          <li>subtotal <span>{symbol}{subTotal}</span></li>
                          {order.ausPost ?
                            <li>shipping <span>{symbol}15</span></li>
                            :
                            null
                          }
                            {order.expressPost ?
                            <li>shipping <span>{symbol}25</span></li>
                            :
                            null
                          }
                            {order.courierPost ?
                            <li>shipping <span>{symbol}30</span></li>
                            :
                            null
                          }
                          <li>tax(GST) <span>$0</span></li>
                        </ul>
                      </div>
                      <div className="final-total">
                        <h3>total <span>{symbol}{order.amount}</span></h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="row order-success-sec">
                      <div className="col-sm-6">
                        <h4>summary</h4>
                        <ul className="order-detail">
                          <li>Email: <span className="order-email">{order.email}</span></li>
                          <li>Order ID: {order.number ? order.number : null}</li>
                          <li>Order Date: {moment(order.date._d).format('DD/MM/YYYY')}</li>
                          <li>Order Total: {symbol}{order.amount}</li>
                        </ul>
                      </div>
                      <div className="col-sm-6">
                        <h4>shipping address</h4>
                        {!order.shippingDetails ?
                          <ul className="order-detail">
                            <li>{order.firstName + ' ' + order.lastName}</li>
                            <li>{order.address}</li>
                            <li>{order.city + ' ' + order.postcode}</li>
                            {order.phone ?
                              <li>{order.phone}</li>
                              :
                              null
                            }
                          </ul>
                          :
                          <ul className="order-detail">
                            <li>{order.shippingFirstName + ' ' + order.shippingLastName}</li>
                            <li>{order.shippingAddress}</li>
                            <li>{order.shippingCity + ' ' + order.shippingPostcode}</li>
                            {order.phone ?
                              <li>{order.phone}</li>
                              :
                              null
                            }
                          </ul>
                        }

                      </div>

                      <div className="col-sm-12 payment-mode">
                        <h4>payment method</h4>
                        <p>SecurePay payment gateway.</p>
                      </div>
                      <div className="col-md-12">
                        <div className="delivery-sec">
                          <h3>expected date of delivery</h3>
                          <h2>{deliveryDate}</h2>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </section>
          </div>
          :
          <section className="p-0">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="error-section">
                    <h1>404</h1>
                    <h2>page not found</h2>
                    <a href="index.html" className="btn btn-solid">back to home</a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      </Fragment>
    )
  }
}

export default orderHistory